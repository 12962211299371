import { useEffect, useContext } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'

import { Context } from 'contexts/context'

import { getUser, userAuthenticated, logout } from 'services/auth'

const Component = ({ component: Component, redirect, ...rest }) => {
  const { user, setUser } = useContext(Context)

  const history = useHistory()

  useEffect(() => {
    (async () => {
      try {
        const data = await getUser()

        setUser(data)
      } catch(error) {
        logout(history)
      }
    })()
  }, [])

  return (
    user && <Route
        {...rest}
        render={(props) =>
          userAuthenticated() ? (
            <Component {...props} />
          ) : (
            <Redirect to={redirect} />
          )
        }
      />
  )
}

export default Component
