import api from "./api/api";

import config from 'config'

export const userAuthenticated = () => {
  return localStorage.getItem(config.tokenKey) !== null
}

export const getUser = async () => {
  const { data } = await api.get(`/admin/me`)

  return data
}

export const getToken = () => localStorage.getItem(config.tokenKey)

export const login = (token) => localStorage.setItem(config.tokenKey, token)

export const logout = (history) => {
  localStorage.removeItem(config.tokenKey)
  return history.push('/login')
}
